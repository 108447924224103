/*
Template Name: Mannat - Admin & Dashboard Template
Author: Mannatthemes
*/

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "config/variables-dark";
@import "../../../node_modules/bootstrap/scss/bootstrap";


.d-flex-between {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-items-center;
}


th.dt-type-numeric {
    background-image: url('https://cdn.datatables.net/1.13.1/images/sort_both.png');
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 10px;
}


